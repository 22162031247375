import React from 'react'

const Payments = (props) => {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <form>
                        <div className="sg-integrate-body sg-tbltop-search" style={{ alignItems: 'baseline' }}>
                            <label htmlFor="exampleInputName2">Telefon: </label>
                            <p style={{fontSize: '16px', marginTop: '16px'}}> {props.paymentList.length > 0 && props.paymentList[0].phone}</p>
                        </div>
                    </form>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="sg-table-container">
                        <div className="sa-card-body">
                            <div className={props.isMobile ? "" : ""}> {/* sa-table-wrapper*/}
                                <table className="table" id="sa-table-form">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '2%' }}>#</th>
                                            <th className="w-20">Namn</th>
                                            <th className="w-20">Belopp</th>
                                            <th className="w-20">Betalningsdag</th>
                                            <th className="w-20">Beskrivning</th>
                                            <th className="w-20">Betalsätt</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.paymentList.length > 0 ?
                                            props.paymentList.map((paymentItem, paymentkey) => {
                                                return (
                                                    <tr key={paymentkey} className="sa-tr-row">
                                                        <td>{paymentkey + 1}</td>
                                                        <td data-label="Namn">
                                                            <div className="form-group sa-form-group">
                                                                <input type="text" readOnly value={paymentItem.name} />
                                                            </div>
                                                        </td>
                                                        <td data-label="Belopp">
                                                            <div className="form-group sa-form-group">
                                                                <input type="text" readOnly value={paymentItem.amount} />
                                                            </div>
                                                        </td>
                                                        <td data-label="Betalningsdag">
                                                            <div className="form-group sa-form-group">
                                                                <input type="text" readOnly value={paymentItem.payment_date} />
                                                            </div>
                                                        </td>
                                                        <td data-label="Beskrivning">
                                                            <div className="form-group sa-form-group">
                                                                <input type="text" readOnly value={paymentItem.description} />
                                                            </div>
                                                        </td>
                                                        <td data-label="Betalsätt">
                                                            <div className="form-group sa-form-group">
                                                                <input type="text" readOnly value={paymentItem.payment_method} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                            (
                                                <tr><td align='center' colSpan={6}>Ingen betalning tillgänglig.</td></tr>
                                            )
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payments
import React from 'react'

const Articles = (props) => {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <form>
                        <div className="sg-integrate-body sg-tbltop-search" style={{ alignItems: 'baseline' }}>
                            <label htmlFor="exampleInputName2">Telefon: </label>
                            <p style={{fontSize: '16px', marginTop: '16px'}}> {props.articleList.length > 0 && props.articleList[0].phone}</p>
                        </div>
                    </form>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="sg-table-container">
                        <div className="sa-card-body">
                            <div className={props.isMobile ? "" : ""}> {/* sa-table-wrapper*/}
                                <table className="table" id="sa-table-form">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '2%' }}>#</th>
                                            <th className="w-12">Namn</th>
                                            <th className="w-12">Pris</th>
                                            <th className="w-12">Beskrivning</th>
                                            <th className="w-12">Dagar att sälja</th>
                                            <th className="w-12">Går ut på</th>
                                            <th className="w-12">Betalsätt</th>
                                            <th className="w-12">Status</th>
                                            <th className="w-12">Provision%</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.articleList.length > 0 ?
                                            props.articleList.map((articleItem, articlekey) => {
                                                return (
                                                    <tr key={articlekey} className="sa-tr-row">
                                                        <td>{articlekey + 1}</td>
                                                        <td data-label="Namn">
                                                            <div className="form-group sa-form-group">
                                                                <input type="text" readOnly value={articleItem.name} />
                                                            </div>
                                                        </td>
                                                        <td data-label="Pris">
                                                            <div className="form-group sa-form-group">
                                                                <input type="text" readOnly value={articleItem.price} />
                                                            </div>
                                                        </td>
                                                        <td data-label="Beskrivning">
                                                            <div className="form-group sa-form-group">
                                                                <input type="text" readOnly value={articleItem.description} />
                                                            </div>
                                                        </td>
                                                        <td data-label="Dagar att sälja">
                                                            <div className="form-group sa-form-group">
                                                                <input type="text" readOnly value={articleItem.daystosell} />
                                                            </div>
                                                        </td>
                                                        <td data-label="Går ut på">
                                                            <div className="form-group sa-form-group">
                                                                <input type="text" readOnly value={articleItem.expiration_date} />
                                                            </div>
                                                        </td>
                                                        <td data-label="Betalsätt">
                                                            <div className="form-group sa-form-group">
                                                                <input type="text" readOnly value={articleItem.payment_method} />
                                                            </div>
                                                        </td>
                                                        <td data-label="Status">
                                                            <div className="form-group sa-form-group">
                                                                <input type="text" readOnly value={articleItem.product_status} />
                                                            </div>
                                                        </td>
                                                        <td data-label="Provision%">
                                                            <div className="form-group sa-form-group">
                                                                <input type="text" readOnly value={articleItem.commission} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                            (
                                                <tr><td align='center' colSpan={9}>Invalid token.</td></tr>
                                            )
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Articles
import React from 'react'

const Template = (props) => {
    return (
        <>
            <div className="sg-container">
                <div className="sg-container-inner">
                    <h2 className="text-uppercase text-center" title="Hem" id="dashboard">
                        {props.storeName}
                    </h2>
                    {props.children}
                </div>
                <div id="footer">
                    <p></p>
                </div>
            </div>
        </>
    )
}

export default Template
import React, { useState, useEffect } from 'react';
//import logo from './logo.svg';
import './App.css';
//import './splitgrid-style.css';
import Template from './Template';
import Articles from './Articles';
import Payments from './Payments';
import TermsCondition from './TermsCondition';

function App() {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
	useEffect(() => {
		window.addEventListener("resize", () => {
			const ismobile = window.innerWidth < 1200;
			if (ismobile !== isMobile) setIsMobile(ismobile);
		}, false);
	}, [isMobile]);

	const [articleList, setShowArticles] = useState([]);
	const [paymentList, setShowPayments] = useState([]);
	const [activeTab, setActiveTab] = useState('articles');
	const [isApproved, setApproved] = useState(false);
	const [storeName, setStoreName] = useState('');
	const [loader, setLoader] = useState(true);

	let supplier = getQueryVariable('supplier')

	//const apiURL = 'http://192.168.21.139/splitgrid/retailer/plugin/individual_suppliers_articles.php?url_token='+supplier

	const apiURL = process.env.REACT_APP_API_URL

	/***********
	 * Function is use to get query string parameter using key
	***/
	function getQueryVariable(variable) {
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			if (pair[0] === variable) { return pair[1]; }
		}
		return (false);
	}

	/***********
	 * Function is use to get article using api [ Api wriiten on server where we have smarty code to add/edit article]
	***/
	useEffect(() => {
		if (isApproved) {
			fetch(apiURL + "individualsupplier?url_token=" + supplier)
				.then(response => response.json())
				.then(responseData => {
					setShowArticles(responseData.aArticalData || []);
					setShowPayments(responseData.aPaymentData || []);
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}, [apiURL, supplier, isApproved]);

	/* Check if supplier have accepted terms and condition before */
	useEffect(() => {
		fetch(apiURL + "checkapproval_and_getstorename?url_token=" + supplier)
			.then(response => response.json())
			.then(responseData => {
				setApproved(responseData.approved === 1 ? true : false)
				setStoreName(responseData.storeName || '')
				setLoader(false)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [apiURL, supplier]);

	/* Approved articles on database for supplier */
	const acceptTermsCodition = () => {
		fetch(apiURL + "approvalarticles?url_token=" + supplier)
			.then(response => response.json())
			.then(responseData => {
				if (responseData.success && responseData.success === 'Approved') {
					setApproved(true)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const activeStyle = {
		background: 'var(--gray)',
		padding: '7px 0px',
		cursor: 'pointer',
		borderRadius: '10px 10px 0 0',
		fontSize: '16px'
	}

	const inActiveStyle = {
		padding: '7px 0px',
		cursor: 'pointer'
	}

	return (
		<Template storeName={storeName}>
			{
				isApproved ? <>
					<div className="row text-center" style={{ borderBottom: '1px solid var(--primary-color)' }}>
						<div className="col-md-2" style={(activeTab === 'articles') ? activeStyle : inActiveStyle} onClick={() => setActiveTab('articles')}>
							Artiklar
						</div>
						<div className="col-md-2" style={(activeTab === 'payments') ? activeStyle : inActiveStyle} onClick={() => setActiveTab('payments')}>
							Betalningar
						</div>
					</div>
					{activeTab === 'articles' ? <Articles isMobile={isMobile} articleList={articleList} /> : null}
					{activeTab === 'payments' ? <Payments isMobile={isMobile} paymentList={paymentList} /> : null}
				</> : <>
					{!loader ? <TermsCondition acceptTermsCodition={acceptTermsCodition} /> : null}
				</>
			}
		</Template>
	);
}

export default App;
